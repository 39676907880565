import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "react-seo-component";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Card, Layout } from "../components";
import { H1, Flex, Section } from "../components/styles";

export default function IndexPage({ data, location }) {
  const {
    title,
    description,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata();

  const { pathname } = location;
  // console.log(pathname);

  const showTemplate = false;

  console.log(`#######                                                                                                                                 ### 
  #    #    #   ##   #    # #    #  ####     ######  ####  #####      ####  #####  ####  #####  #####  # #    #  ####     #####  #   # ### 
  #    #    #  #  #  ##   # #   #  #         #      #    # #    #    #        #   #    # #    # #    # # ##   # #    #    #    #  # #  ### 
  #    ###### #    # # #  # ####    ####     #####  #    # #    #     ####    #   #    # #    # #    # # # #  # #         #####    #    #  
  #    #    # ###### #  # # #  #        #    #      #    # #####          #   #   #    # #####  #####  # #  # # #  ###    #    #   #       
  #    #    # #    # #   ## #   #  #    #    #      #    # #   #     #    #   #   #    # #      #      # #   ## #    #    #    #   #   ### 
  #    #    # #    # #    # #    #  ####     #       ####  #    #     ####    #    ####  #      #      # #    #  ####     #####    #   ###`);
  console.log("This site crafted by Peter Curcio. Hope you enjoy!");

  return (
    <Layout pathname={pathname}>
      <Seo
        title={`Home`}
        titleTemplate={title}
        description={description}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />

      {showTemplate && <p>This is using src/pages/index.js</p>}

      <Section as="section" id="projects">
        <H1 className="homepage-section-title">What I've been working on:</H1>
        <Flex>
          {data.projects.nodes.map(({ id, excerpt, frontmatter }) => (
            <Card
              key={id}
              link={`/projects/${frontmatter.slug}`}
              image={
                frontmatter.featureImage &&
                getImage(
                  frontmatter.featureImage.childImageSharp.gatsbyImageData
                )
              }
              heading={frontmatter.title}
              excerpt={excerpt}
            />
          ))}
        </Flex>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query SITE_INDEX_QUERY {
    projects: allMdx(
      filter: { fileAbsolutePath: { regex: "/src/projects/" } }
      sort: { fields: frontmatter___sortOrder, order: ASC }
    ) {
      nodes {
        id
        slug
        excerpt
        frontmatter {
          slug
          title
          featureImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    blogPosts: allMdx(
      filter: { fileAbsolutePath: { regex: "/src/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          slug
          title
          featureImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
              )
            }
          }
        }
      }
    }
    aboutPage: mdx(slug: { eq: "about/" }) {
      body
    }
    codeImg01: file(relativePath: { eq: "Coding_Outline.svg" }) {
      publicURL
    }
    codeImg02: file(relativePath: { eq: "Computer_display_Flatline.svg" }) {
      publicURL
    }
    codeImg03: file(relativePath: { eq: "Computer_display_Outline.svg" }) {
      publicURL
    }
    waves01: file(relativePath: { eq: "layered-waves-haikei.svg" }) {
      publicURL
    }
  }
`;
